.section {
    margin-bottom: 80px;

    & > * ~ * {
        margin-top: 70px;
    }

    &__title {
        font-size: 2rem;
        text-transform: capitalize;
        text-align: center;
    }
}
