.hero-slider {
    height: 100vh;
    max-height: 1080px;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
    margin-top: calc(#{$header-height} * -1);

    @include tablet {
        margin-top: calc(#{$header-tablet-height} * -1);
    }

    @include mobile {
        margin-top: calc(#{$header-mobile-height} * -1);
    }

    &__item {
        @include flex(center, space-between);

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        inset: 0;

        @include tablet {
            flex-direction: column-reverse;
            padding-top: $header-tablet-height;
        }

        @include mobile {
            padding-top: unset;
            padding-bottom: 40px;
        }

        &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: visible;
        }

        &__info {
            width: 58.33%;

            @include tablet {
                width: 100%;
                flex-grow: 1;
            }

            &__title,
            &__description {
                margin-bottom: 60px;

                @include tablet {
                    margin-bottom: 15px;
                }
            }

            &__title {
                font-family: "M PLUS Rounded 1c", sans-serif;
                font-weight: 600;
                font-size: 4.5rem;
                line-height: 6.75rem;
                overflow: hidden;

                @include mobile {
                    font-size: 2.5rem;
                    line-height: 3.75rem;
                }
            }

            &__description {
                color: $txt-second-color;
                font-size: 1.5rem;
                line-height: 2.25rem;
                overflow: hidden;

                @include mobile {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }

            &__btn {
                overflow: hidden;
            }

            &__title > span,
            &__description > span,
            &__btn button {
                display: block;
                transform: translateY(-100%);
                transition: transform 0.5s ease;
            }
        }

        &.active &__info {
            &__title > span,
            &__description > span,
            &__btn button {
                transform: translateY(0);
            }
        }

        &__image {
            flex-grow: 1;
            position: relative;
            height: 100%;
            z-index: 99;
            pointer-events: none;

            @include tablet {
                width: 100%;
                height: 60%;
                @include flex(center, center);
                flex-grow: unset;
                z-index: 98;
            }

            img {
                height: 100vh;
                position: absolute;
                bottom: 0;
                right: 0;
                transform: scale(0) rotate(65deg);
                transition: transform 0.5s
                    cubic-bezier(0.175, 0.885, 0.32, 1.275);

                @include tablet {
                    height: 100%;
                    position: relative;
                }
            }

            .shape {
                $size: 472px;
                height: $size;
                width: $size;
                position: absolute;
                right: 0;
                top: 50%;
                border-radius: 58% 42% 38% 62% / 42% 55% 45% 58%;
                transform: translate(0, -50%);
                transition: border-radius 13s ease;
                animation: border 10s infinite;

                @include tablet {
                    $size: 250px;
                    height: $size;
                    width: $size;
                    right: 50%;
                    transform: translate(50%, -50%);
                }
            }
        }

        &.active &__image > img {
            transform: scale(1) rotate(0);
        }
    }

    &__control {
        @include flex(center, center);
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        @include tablet {
            bottom: 20px;
        }

        &__item ~ &__item {
            margin-left: 10px;
        }

        &__item {
            @include flex(center, center);
            font-size: 2rem;
            cursor: pointer;

            &:hover {
                color: $main-color;
            }

            .index {
                font-size: 1.5rem;
            }
        }
    }
}

@keyframes border {
    0% {
        border-radius: 58% 42% 38% 62% / 42% 55% 45% 58%;
    }
    50% {
        border-radius: 31% 69% 59% 41% / 28% 24% 76% 72%;
    }
    100% {
        border-radius: 58% 42% 38% 62% / 42% 55% 45% 58%;
    }
}
